import { Moment } from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { optionsPOST, url_interna } from "../../../config/constants";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";

import { useLayout } from "hooks/otros/useLayout";
import { UserContext } from "context/UserContext";

type rankingSuper = {
  Cod_Bodega: number;
  LlaveQR: String;
  Descripcion: String;
};

const breadcrumbs = [{ Label: "Recursos Humanos", Url: "/qr-bodega" }, { Label: "QR Bodega" }];
const openKeys = [breadcrumbs[0].Label];
export function useQRBodega() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);
  const [datosInicial, setDatos] = useState<rankingSuper[]>([]);
  const { userData } = useContext(UserContext);

  const { isError } = useVerifyResponse();
  const getBodegas = useCallback(async () => {
    try {
      const res = await fetch(
        url_interna + "api/inventarios/obtener_bodega_qr",
        optionsPOST(JSON.stringify({ codUsuario: userData?.id }), {
          "api-token": "apitoken",
        })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      console.log(respuesta);
      if (respuesta.msg !== "err") {
        const respuestas: rankingSuper[] = respuesta.data;
        setDatos(
          respuestas.map(({ Cod_Bodega, LlaveQR,Descripcion }) => ({
            Cod_Bodega,
            LlaveQR,
            Descripcion
          }))
        );
      } else {
        setDatos([]);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDatos([]);

      return { msg: "no_conexion", status: false };
    }
  }, [isError]);

  useEffect(() => {
    getBodegas();
  }, [getBodegas]);

  return {
    datosInicial,
  };
}
