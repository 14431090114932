import { Col, Row, DatePicker, Table, Card, Select, Button, Spin } from "antd";
import "moment/locale/es";

import { useProformasBodega } from "hooks/inventarios/reportes/useProformasBodega";

export function ProformasBodegas() {
  const {
    columns,
    setFechas,
    filteredData,
    datosPeriodos,
    setBodega,
    estadoBotones,
    bodega,
    dataEstado,
    handleChangeButton,
    disabledDate,
    fechaInicio,
    fechaFin,
    columsDatatableDetalleProductos,
    //handleClickDownloadData,
  } = useProformasBodega();
  return (
    <Row style={{ padding: "16px", marginRight: "0px", backgroundColor: "#fff" }} gutter={[16, 16]}>
      <Col xs={24} sm={12} md={14} lg={16} xl={18}>
        <Select
          placeholder="Selecione una bodega"
          style={{ width: 250, marginRight: "10px", marginTop: "0px" }}
          onChange={(value) => setBodega(value)}
          allowClear
          showSearch
          options={datosPeriodos}
        />
        <DatePicker.RangePicker
          onChange={(value) => {
            if (value && value.length === 2 && value[0] && value[1]) {
              setFechas([value[0], value[1]]);
            }
          }}
          disabledDate={disabledDate}
        />
        <Button
          type="primary"
          style={{ marginLeft: "5px" }}
          //icon={<PoweroffOutlined />}
          loading={estadoBotones}
          disabled={bodega === undefined || fechaInicio === null || fechaFin === null}
          onClick={handleChangeButton}
        >
          Generar reporte
        </Button>
      </Col>

      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Card>
          {!dataEstado && estadoBotones && (
            <div style={{ textAlign: "center" }}>
              <div style={{ margin: "0 auto" }}>
                <Spin />
              </div>
            </div>
          )}
          {dataEstado && (
          <Table
          columns={columns}
          dataSource={filteredData}
          scroll={{ x: true }}
          size="small"
          pagination={{ pageSize: 10, hideOnSinglePage: true, position: ["bottomCenter"] }}
              expandable={{
                expandedRowRender: (record) => {
                  return (
                    <>
                      <Table
                        className="tabla-interna"
                        style={{ width: "100%", margin: "10px" }}
                        columns={columsDatatableDetalleProductos}
                        dataSource={record.productos}
                        pagination={false}
                      />
                    </>
                  );
                },
              }}
            />
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default ProformasBodegas;
