import { Button, Card, Col, DatePicker, List, Row, Select, Table, Tooltip, Typography } from "antd"
import { useControlDineroCaja } from "hooks/cajas/useControlDineroCaja"
import { useLayout } from "hooks/otros/useLayout"
import { FileExcelFilled } from "@ant-design/icons"

const { Title } = Typography
const { RangePicker } = DatePicker

const breadcrumbs = [{ Label: "Cajas", Url: "/cajas" }, { Label: "Dinero en Caja por Depositar" }]

const openKeys = [breadcrumbs[0].Label]
const selectKeySider = `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`
export function ControlDineroCaja() {
  useLayout(breadcrumbs, openKeys, selectKeySider)

  const { dataFarmacias, valueFarmacia, onchangeFarmacia, disableFutureDates, handleDateChange, dataControlDinero, exportToExcel } =
    useControlDineroCaja()

  return (
    <>
      <Row style={{ background: "white", padding: "5px" }} align="middle" justify="center">
        <Col xs={24} sm={24} md={11} style={{ paddingLeft: "20px" }}>
          <Title level={4}>Dinero en Caja por Depositar</Title>
        </Col>
        <Col xs={24} sm={24} md={13}>
          <Row justify="end">
            <Col md={11} sm={24} xs={24} style={{ paddingRight: "5px" }}>
              <Row>
                <Title style={{ fontSize: "12px" }}>Farmacia:</Title>
              </Row>
              <Row>
                <Select
                  showSearch
                  size="small"
                  style={{ width: "100%" }}
                  options={dataFarmacias}
                  value={valueFarmacia}
                  onChange={onchangeFarmacia}
                  filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                />
              </Row>
            </Col>
            <Col md={13} sm={24} xs={24}>
              <Row>
                <Title style={{ fontSize: "12px" }}>Fechas</Title>
              </Row>
              <Row>
                <Col xs={22} sm={22} style={{ paddingRight: "5px" }}>
                  <RangePicker
                    size="small"
                    style={{ width: "100%" }}
                    format={"YYYY/MM/DD"}
                    disabledDate={disableFutureDates}
                    onCalendarChange={handleDateChange} /* value={fechasSeleccionadas} */
                  />
                </Col>
                <Col xs={2} sm={2}>
                 <Tooltip title="Descargar Excel"> <Button type="primary" size="small" style={{ backgroundColor: "#00B050", borderColor: "#00B050" }} onClick={exportToExcel}>
                    <FileExcelFilled />
                  </Button></Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ background: "white", paddingInline: "8px", paddingTop: "10px"}} className="table-container">
        <List
          style={{ width: "100%" }}
          itemLayout="vertical"
          size="small"
          dataSource={dataControlDinero}
          pagination={{
            pageSize: 4,
          }}
          renderItem={(item) => (
            <>
              <Card style={{ border: "white" }}>
                <Row style={{ width: "100%" }}>
                  <Title level={4}>{item.Bodega}</Title>
                </Row>
                <Row style={{ background: "white", width: "100%", padding: "5px" }} className="table-container">
                  <Table
                    style={{ width: "100%" }}
                    columns={[...item.columnasprincipales, ...item.columnasadd]}
                    dataSource={item.dependientes}
                    pagination={false}
                  />
                </Row>
              </Card>
            </>
          )}
        />
      </Row>
    </>
  )
}
