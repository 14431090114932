import { Moment } from "moment";
import { useCallback, useEffect, useState } from "react";
import { url, url_interna } from "../../../config/constants";

import { useLocalStorage } from "hooks/otros/useLocalStorage";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { TableColumnsType } from "antd";

import { useLayout } from "hooks/otros/useLayout";

import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
import { optionsGet } from "config/methods";
import { RangePickerProps } from "antd/lib/date-picker";

type InventarioReporte = {
  Nombres_Rol: string;
  Nombre_Cliente: string;
  Ruc_Cliente: string;
  Total: string;
  Fecha: string;
  productos: PropsProductos[];
  key: React.Key;
};
interface PropsProductos {
  key: React.Key;
  Descripcion: string;
  Cod_Barra: string;
  Cant_Unidad: number;
  Cant_Fraccion: number;
}
type DatosPeriodo = {
  label: string;
  value: string;
};

const breadcrumbs = [{ Label: "Inventarios", Url: "/inventario" }, { Label: "Reportes", Url: "/inventarios/reportes" }, { Label: "Proformas" }];

const openKeys = [breadcrumbs[0].Label, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label];
export function useProformasBodega() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[1].Label + "/" + breadcrumbs[2].Label);

  const [[fechaInicio, fechaFin], setFechas] = useState<[Moment | null, Moment | null]>([null, null]);
  const [datos, setDatos] = useState<InventarioReporte[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<InventarioReporte[]>([]);

  const [dataEstado, setDataEstado] = useState<boolean>(false);

  const [bodega, setBodega] = useState<number>();
  const [estadoBotones, setDataBoton] = useState<boolean>(false);
  const [datosPeriodos, setDatosPeriodos] = useState<DatosPeriodo[]>([]);
  const { getColumnSearchPropsNew: getColumnSearchPropsNewVisorReporte } = useSearchTableNew<InventarioReporte>();
  let { optionsPost } = useLocalStorage();
  const { isError } = useVerifyResponse();

  const getDatosBodegas = async (codBodega: number, fechaInicio: Moment, fechaFin: Moment) => {
    setDataEstado(false);
    setDataBoton(true);
    try {
      const res = await fetch(
        url + "apifsg-pr/inventarios/obtener_proformas_pendientes",
        optionsPost({
          codBodega: codBodega,
          fechaInicio: fechaInicio!.format("DD/MM/YYYY"),
          fechaFin: fechaFin!.format("DD/MM/YYYY"),
        })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: InventarioReporte[] = respuesta.data;
        setDataEstado(true);

        setDataBoton(false);
        setDatos(
          respuestas.map(({ Nombres_Rol, Nombre_Cliente, Ruc_Cliente, Total, Fecha, productos, key }) => ({
            Nombres_Rol,
            Nombre_Cliente,
            Ruc_Cliente,
            Total,
            Fecha,
            productos,
            key,
          }))
        );
      } else {
        setDataEstado(false);
        setDataBoton(false);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDataEstado(false);
      setDataBoton(false);
      return { msg: "no_conexion", status: false };
    }
  };

  const getDatosIniciales = useCallback(async () => {
    setDatosPeriodos([]);
    try {
      const res = await fetch(url_interna + "api/inventarios/obtener_bodegas_piloto", optionsGet());
      if (isError(res)) throw new Error();
      const respuesta = await res.json();

      if (respuesta.msg !== "err") {
        const respuestas: DatosPeriodo[] = respuesta.data;
        if (respuestas.length > 0) {
          setDatosPeriodos(
            respuestas.map(({ label, value }) => ({
              label,
              value,
            }))
          );
        } else {
          setDatosPeriodos([]);
        }
      } else {
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      return { msg: "no_conexion", status: false };
    }
  }, [isError]);

  useEffect(() => {
    getDatosIniciales();
  }, [getDatosIniciales]);

  /* const setRowClassName = (record: DatosInventariosEspecial) => {
    if (record.ValorDiferencia > 0) {
      return "highlight-row"; // Esta es la clase CSS que se aplicará
    }
    return ""; // No se aplica ninguna clase especial si no se cumplen las condiciones
  };*/
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Deshabilita las fechas futuras respecto al final del día actual
    const startDate = moment("2024-08-01");
    const endDate = moment().endOf("day"); // Define el final del día actual
    return current && (current < startDate.startOf("day") || current > endDate);
  };
  useEffect(() => {
    if (!searchText) {
      setFilteredData(datos); // Si no hay texto de búsqueda, muestra todos los datos
    } else {
      const filtered = datos.filter((item) => item.Nombre_Cliente.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datos]);
  const columns: TableColumnsType<InventarioReporte> = [
    {
      title: "Dependiente",
      dataIndex: "Nombres_Rol",
      key: "Nombres_Rol",
      filters: [...new Set(filteredData.map(({ Nombres_Rol }) => Nombres_Rol))].map((Nombres_Rol) => ({
        text: Nombres_Rol,
        value: Nombres_Rol,
      })),
      filterSearch: true,
      onFilter: (value, record) => value === record.Nombres_Rol,
      className: "letraPequenaVisor2",
    },
    {
      title: "Cliente",
      dataIndex: "Nombre_Cliente",

      ...getColumnSearchPropsNewVisorReporte("Nombre_Cliente"),
      key: "Nombre_Cliente",
      className: "letraPequenaVisor2",
    },
    {
      title: "Ruc cliente",
      dataIndex: "Ruc_Cliente",
      key: "Ruc_Cliente",
      className: "letraPequenaVisor2",
    },
    {
      title: "Fecha",
      dataIndex: "Fecha",
      //8align: "right",
      className: "letraPequenaVisor2",
    },

    {
      title: "Total",
      dataIndex: "Total",
      key: "Total",
      className: "letraPequenaVisor2",

      align: "right",
    },
  ];
  const columsDatatableDetalleProductos: TableColumnsType<PropsProductos> = [
    {
      title: "Producto",
      dataIndex: "Descripcion",
      key: "Descripcion",

    },
    {
      title: "Código barras",
      dataIndex: "Cod_Barra",
      key: "Cod_Barra",
    },
    {
      title: "Cajas",
      dataIndex: "Cant_Unidad",
      key: "Cant_Unidad",
    },
    {
      title: "Fracciones",
      dataIndex: "Cant_Fraccion",
      key: "Cant_Fraccion",
    },
  ];

  const handleChangeButton = () => {
    try {
      if (bodega !== undefined && bodega >= 0 && fechaInicio && fechaFin) {
        getDatosBodegas(bodega, fechaInicio, fechaFin);
      } //
    } catch (error) {
      console.log(error);
    }
  };


  return {
    columns,
    setFechas,
    filteredData,
    datosPeriodos,
    setBodega,
    estadoBotones,
    bodega,
    handleChangeButton,
    dataEstado,
    disabledDate,
    fechaInicio,
    fechaFin,
    columsDatatableDetalleProductos,
    //handleClickDownloadData,
  };
}
