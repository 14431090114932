import { Button, Tag, Tooltip } from 'antd'
import { ColumnsType } from 'antd/lib/table/interface'
import React, {useCallback, useContext, useEffect, useState } from 'react'
import { EyeOutlined} from "@ant-design/icons"
import moment from 'moment'
import { useSearchTableNew } from 'hooks/otros/useSearchTableNew'
import { UserContext } from 'context/UserContext'
import { url } from 'config/constants'
import { optionsPost, swalSuccess } from 'config/methods'
import { useExport } from 'hooks/otros/useExport'

export interface DataTypeTareas {
    key: React.Key
    cod_tipo_Incidente: string
    incidente: string
    fecha: Date
    cod_bodega: string
    bodega: string
    cod_usuario_solicita: string
    usuario_solicita: string
    cod_usuario_asignado: string
    usuario_asignado: string
    cod_usuario_registra: string
    usuario_registra: string
    descripcion: string
    fecha_inicio: Date
    fecha_fin: Date
    estado: string
  }
  
  export interface DataTypeTareasHis {
    key: React.Key
    Descripcion: string
    Cod_Usuario_Registra: string
    Nombres: string
    Tipo_Atencion: string
    Fecha_Registra: Date
  }
  
export  function useTareasFinalizadas() {
    const [disabled, setdisabled]=useState(false)
    const { getColumnSearchPropsNew } = useSearchTableNew<DataTypeTareas>()
    const { userData } = useContext(UserContext);
    const [dataTareas,setdataTareas]=useState<DataTypeTareas[]>([])
    const [Codtarea, setCodtarea]=useState("")
    const [Fecha, setFecha]=useState(moment())
    const [Estado,setEstado]=useState("")
    const [Farmacia, setFarmacia]=useState("")
    const [Fecha_Inicio, setFecha_Inicio]=useState(moment())
    const [Fecha_fin, setFecha_fin]=useState(moment())
    const [usuario_asignado,setusuario_asignado]=useState("")
    const [usuario_solicita, setusuario_solicita]=useState("")
    const [usuario_registra, setusuario_registra]=useState("")
    const [descripcion_general, setdescripcion_general]=useState("")
    const [dataTareasHis, setdataTareasHis]=useState<DataTypeTareasHis[]>([])
    const { exportToExcel } = useExport()
    const [incidente, setincidente]=useState("")
    const [fechasSeleccionadas, setFechasSeleccionadas] = useState<[moment.Moment, moment.Moment]>([
      moment( moment().endOf("day"), 'YYYY/MM/DD'), moment( moment().endOf("day"), 'YYYY/MM/DD')
    ])
    const [ismodalOpen, setismodalOpen]=useState(false)
    const [descripcion, setdescripcion]=useState("")
    const [Tipo_Atencion, setTipo_Atencion]=useState("")
    const [cod_tareahis, setcod_tareahis]=useState("")
    const [Fechahis, setFechahis]=useState("")
    const [showMessage, setshowMessage]=useState(false)
 
  const columnsTareas: ColumnsType<DataTypeTareas>=[
    {
      title:'N Tarea',
      dataIndex: "key"
    },
    {
      title: "Farmacia",
      dataIndex: "bodega",
      ...getColumnSearchPropsNew("bodega")
    },
    {
        title:'Tipo Incidente',
        dataIndex: "incidente",
        ...getColumnSearchPropsNew("incidente")
      },
      {
        title:'Descripción',
        dataIndex:'descripcion',
        ...getColumnSearchPropsNew("descripcion")
    },
    {
      title:'Asignado a',
      dataIndex:'usuario_asignado',
      ...getColumnSearchPropsNew("usuario_asignado")
  },
    {
       title:'Fecha de Registro',
       dataIndex:"fecha",
       render:(key)=>{
        return moment.parseZone(key).format("DD/MM/YYYY")
       }
    },
    {
        title:'Fecha de Inicio',
        dataIndex:"fecha_inicio",
        render:(key)=>{
          return key!==null?moment.parseZone(key).format("DD/MM/YYYY"):''
         }
     },
     {
        title:'Fecha Fin',
        dataIndex:"fecha_fin",
        render:(key)=>{
          return key!==null?moment.parseZone(key).format("DD/MM/YYYY"):''
         }
     },
    {
        title:'Estado',
        dataIndex:'estado',
        render:(key)=>{
          return (<><Tag color="green" >{key}</Tag></>)
         }
    },
    {
        title:'Acción',
        fixed:'right',
        width:'98px',
        render:(key)=>{
            return(
                <>
                  <Tooltip title="Ver"><Button type={"primary"} size="small" style={{ marginRight: "2px", marginBottom:'1px' }} onClick={() => btn_Ver(key)} >
                  <EyeOutlined />
                </Button></Tooltip>
                </>
            )
        }
    }
  ]

      
  const getTareas = useCallback(async () => {
    try{
      const res = await fetch(`${url}fapifsg-pr/sistemas/getTareas`, optionsPost({estado:'FINALIZADO', perfil: userData?.id_perfil, fecha_inicio: fechasSeleccionadas?moment(fechasSeleccionadas[0]).format("YYYY-MM-DD"):'', fecha_fin: fechasSeleccionadas?moment(fechasSeleccionadas[1]).format("YYYY-MM-DD"):''})).then((data) => data.json())
    let newdata:any=[]
    if(res){
        newdata = Array.from(res).map((item: any, key: any) => ({
          key: item.Cod_Tarea_Soporte,
          cod_tipo_Incidente: item.Cod_Tipo_Incidente,
          incidente: item.Incidente,
          fecha: item.Fecha,
          cod_bodega: item.Cod_Bodega,
          bodega: item.Bodega,
          cod_usuario_solicita: item.Cod_Usuario_Solicita,
          usuario_solicita: item.usuario_solicita,
          cod_usuario_asignado: item.Cod_Usuario_Asignado,
          usuario_asignado: item.usuario_asignado,
          cod_usuario_registra: item.Cod_Usuario_Registra,
          usuario_registra: item.usuario_registra,
          descripcion: item.Descripcion,
          fecha_inicio: item.Fecha_Inicio,
          fecha_fin:  item.Fecha_fin,
          estado: item.Estado
      }))
     // console.log(newdata)
     }
     setdataTareas(newdata)
    }catch(error){
      console.log(error)
    }
  },[userData, fechasSeleccionadas])

  useEffect(()=>{
    getTareas()
},[getTareas])

const handleDateChange = (dates: any, dateStrings: any) => {
  setFechasSeleccionadas(dateStrings)
}

const getTareasHis=async(cod_tarea: string)=>{
  try{
      const res = await fetch(
        `${url}fapifsg-pr/sistemas/getTareasHis`,
        optionsPost({cod_tarea_soporte: cod_tarea })
      ).then(async(data) => await data.json())
      //console.log(res)
      setdataTareasHis(res?res:[])
  }catch(error){
    console.log(error)
  }
}


const btn_Ver=( key: DataTypeTareas )=>{
  setCodtarea((key.key).toString())
  setdisabled(true)
  setFecha(moment.parseZone(key.fecha))
  setEstado(key.estado)
  if(key.fecha_inicio){
    setFecha_Inicio(moment.parseZone(key.fecha_inicio))
  }else{
    setFecha_Inicio(moment())
  }
  if(key.fecha_fin){
    setFecha_fin(moment.parseZone(key.fecha_fin))
  }else{
    setFecha_fin(moment())
  }
  //??moment())
  setFarmacia(key.bodega)
  setusuario_asignado(key.usuario_asignado)
  setusuario_solicita(key.usuario_solicita)
  setdescripcion_general(key.descripcion)
  getTareasHis((key.key).toString())
  setincidente(key.incidente)
  setusuario_registra(key.usuario_registra)
}


const btn_Excel = () => {
  const dataExport = dataTareas.map((item)=>{
      return{
        Num_Tarea: item.key,
        Cod_Bodega: item.cod_bodega,
        Farmacia: item.bodega,
        Incidente: item.incidente,
        Descripción:item.descripcion,
        "Usuario Asignado": item.usuario_asignado,
        "Fecha de Registro": item.fecha!==null?moment.parseZone(item.fecha).format("DD/MM/YYYY"):'',
        "Fecha de Inicio": item.fecha_inicio!==null?moment.parseZone(item.fecha_inicio).format("DD/MM/YYYY"):'',
        "Fecha Fin": item.fecha_fin!==null?moment.parseZone(item.fecha_fin).format("DD/MM/YYYY"):'',
      }
  })
  exportToExcel(dataExport, `Reporte_Tareas_Finalizadas${moment().format("DDMMYYYY")}`)
}

const disabledDate = (current: any) => {
  // Si la fecha actual es mayor a la fecha seleccionada, deshabilita la fecha
  return current && current > moment().endOf("day")
}

  const btn_Editar=(key:DataTypeTareasHis)=>{
    setismodalOpen(true)
    setcod_tareahis((key.key).toString())
    setTipo_Atencion(key.Tipo_Atencion)
    setdescripcion(key.Descripcion)
    setFechahis((key.Fecha_Registra).toString())
  }

  const onchangeTipoAtencion = async (value: string) => {
    setshowMessage(false)
    setTipo_Atencion(value)
  }

  
  const onchangeDescripcion = (event: any) => {
    setshowMessage(false)
    let newValue = event.target.value
    setdescripcion(newValue)
  }

  const btn_Cancel=()=>{
    setshowMessage(false)
    setismodalOpen(false)
  }


  const btn_Guardar=async()=>{
    try {
      if(descripcion===''||descripcion===null || Tipo_Atencion==='' || Tipo_Atencion===null){
        setshowMessage(true)
        return
      }
      const res = await fetch(`${url}fapifsg-pr/sistemas/updateTareaHis`, optionsPost({cod_tarea_soporteh:cod_tareahis, descripcion:descripcion,tipo_atencion:Tipo_Atencion}))
      if(res.status===200){
        getTareasHis(Codtarea)
        setismodalOpen(false)
        swalSuccess({text: 'Se actualizó correctamente'})
      }
    } catch (error) {
      console.log(error)
    }

  }


  return {
    disabled,
    columnsTareas,
    dataTareas,
    Codtarea,
    Fecha,
    Estado,
    Fecha_Inicio,
    Farmacia,
    usuario_asignado,
    usuario_solicita,
    usuario_registra,
    descripcion_general,
    dataTareasHis,
    setdisabled,getTareas,
    incidente,
    Fecha_fin,
    btn_Excel,
    fechasSeleccionadas, 
    handleDateChange,
    disabledDate,
    ismodalOpen,
    btn_Editar,
    btn_Cancel,
    onchangeTipoAtencion,
    Tipo_Atencion,
    descripcion,
    Fechahis,
    cod_tareahis,
    onchangeDescripcion,
    btn_Guardar,
    showMessage,
    setFechasSeleccionadas,
  }
}
