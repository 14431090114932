import { Moment } from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { optionsPOST, url_interna } from "../../config/constants";
import { useVerifyResponse } from "hooks/otros/useVerifyReponse";
import moment from "moment";
import { Button, TableColumnsType } from "antd";
import { EyeOutlined } from "@ant-design/icons";

import { useLayout } from "hooks/otros/useLayout";
import { UserContext } from "context/UserContext";
import "./style.css";
import { useSearchTableNew } from "hooks/otros/useSearchTableNew";
//import { useSearchTableNew } from "hooks/otros/useSearchTableNew";

interface PropsDescripcionDependientes {
  key: React.Key;
  Nombres_Rol: string;
  totalVenta: number;
  participacion: number;
  facturas: number;
}
interface PropsDescripcionBodegas {
  key: React.Key;
  Descripcion: string;
  ciudad: string;
  cumplimiento: number;
  valor: number;
  porcentaje: number;
  dependientes: PropsDescripcionDependientes[];
}

type rankingSuper = {
  Nombres: string;
  Ranking: String;
  TotalPorcentaje: number;
  datas: PropsDescripcionBodegas[];
  key: React.Key;
};

const breadcrumbs = [{ Label: "Indicadores", Url: "/ranking-supervisores" }, { Label: "Ranking supervisores" }];

const openKeys = [breadcrumbs[0].Label];
export function useRankingSupervisores() {
  useLayout(breadcrumbs, openKeys, breadcrumbs[0].Label + "/" + breadcrumbs[1].Label);
  const [mes, setMes] = useState<Moment>(moment());
  const [datosInicial, setDatos] = useState<rankingSuper[]>([]);
  const [searchText] = useState("");
  const [filteredData, setFilteredData] = useState<rankingSuper[]>([]);
  const { getColumnSearchPropsNew } = useSearchTableNew<PropsDescripcionBodegas>();
  const [datakey, setDatakey] = useState<PropsDescripcionDependientes[]>([]);
  const { userData } = useContext(UserContext);
  
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { isError } = useVerifyResponse();
  const getBodegas = useCallback(async () => {
    console.log(userData);
    try {
      const res = await fetch(
        url_interna + "api/reportes/obtener_ranking_supervisores",
        optionsPOST(JSON.stringify({ mes: mes.format("YYYYMM") }), {
          "api-token": "apitoken",
        })
      );
      if (isError(res)) throw new Error();
      const respuesta = await res.json();
      if (respuesta.msg !== "err") {
        const respuestas: rankingSuper[] = respuesta.data;
        setDatos(
          respuestas.map(({ Nombres, Ranking, TotalPorcentaje, datas, key }) => ({
            Nombres,
            Ranking,
            TotalPorcentaje,
            datas,
            key,
          }))
        );
      } else {
        setDatos([]);
        return { msg: "Ocurrio un error", status: false };
      }
    } catch (e) {
      setDatos([]);

      return { msg: "no_conexion", status: false };
    }
  }, [isError, mes]);

  useEffect(() => {
    getBodegas();
  }, [getBodegas]);

  useEffect(() => {
    if (!searchText) {
      setFilteredData(datosInicial);
    } else {
      const filtered = datosInicial.filter((item) => item.Nombres.toLowerCase().includes(searchText.toLowerCase()));
      setFilteredData(filtered);
    }
  }, [searchText, datosInicial]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const formateaCantidad = (cantidad: number) => {
    const totalCostoFormateado = (cantidad / 1).toLocaleString("de-DE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return totalCostoFormateado;
  };
  const calcularTotales = () => {
    let facturas = 0.0;
    let total = 0.0;

    datakey.forEach((item) => {
      facturas += item.facturas;
      total += item.totalVenta;
    });
    facturas = parseFloat(facturas.toFixed(2));
    total = parseFloat(total.toFixed(2));

    return { facturas, total };
  };
  const columns: TableColumnsType<rankingSuper> = [
    {
      title: "Ranking",
      dataIndex: "Ranking",
      key: "Ranking",
      width: "100px",
    },
    {
      title: "Nombre",
      dataIndex: "Nombres",
      key: "Nombres",
    },
    {
      title: "Porcentaje de cumplimiento",
      dataIndex: "TotalPorcentaje",
      key: "totalPorcentaje",
      //render: (text, record) => `${text} %`, // Concatena el signo de porcentaje al valor
      render: (_, record) => (
        <span style={{ color: record.TotalPorcentaje === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.TotalPorcentaje) + "%"}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
  ];
  const isRowExpandable = useCallback(
    (record: rankingSuper) => {
      const esSupervisor = userData?.perfiles.some((datos) => datos.id_perfil === 35);
      //console.log(esSupervisor);

      return userData?.id === record.key || !esSupervisor; // Assuming 'allAccess' is an admin-like ID
    },
    [userData]
  );
  const getRowClassName = (record: rankingSuper) => {
    return isRowExpandable(record) ? "" : "row-disabled";
  };
  const citiesForSpecificKey = datosInicial
    .filter((item) => item.key === userData?.id || !userData?.perfiles.some((datos) => datos.id_perfil === 35))
    .flatMap((item) => item.datas.map((data) => data.ciudad));

  const uniqueCities = Array.from(new Set(citiesForSpecificKey))
    .filter((city) => city)
    .map((city) => ({
      text: city,
      value: city,
    }));
  const columsDataDependiente: TableColumnsType<PropsDescripcionBodegas> = [
    {
      title: "Farmacia",
      dataIndex: "Descripcion",
      key: "Descripcion",
      width: "280px",
      ...getColumnSearchPropsNew("Descripcion"),
    },
    {
      title: "Ciudad",
      dataIndex: "ciudad",
      key: "ciudad",
      width: "100px",
      filters: uniqueCities,
      onFilter: (value, record) => record.ciudad === value,
      filterSearch: true,
    },
    {
      title: "Cuota",
      dataIndex: "valor",
      key: "valor",
      align: "right",
      render: (_, record) => (
        <span style={{ color: record.valor === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.valor)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Venta",
      dataIndex: "cumplimiento",
      key: "cumplimiento",
      align: "right",
      sorter: (a, b) => a.cumplimiento - b.cumplimiento,
      render: (_, record) => (
        <span style={{ color: record.cumplimiento === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.cumplimiento)}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
    {
      title: "Porcentaje",
      dataIndex: "porcentaje",
      key: "porcentaje",
      align: "left",
      sorter: (a, b) => a.porcentaje - b.porcentaje,
      render: (_, record) => (
        <span style={{ color: record.porcentaje === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.porcentaje) + "%"}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
      //render: (text, record) => `${text} %`, // Concatena el signo de porcentaje al valor
    },
    {
      title: "Dependientes",
      dataIndex: "dependientes",
      key: "dependientes",
      render: (value, record) => (
        <Button
          icon={<EyeOutlined style={{ fontSize: "1.3rem" }} />}
          type="text"
          onClick={() => {
            console.log(value);
            setDatakey(value);
            showModal();
          }}
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          block
        />
      ),
      fixed: "right",
    },
  ];

  const columsDataProductos: TableColumnsType<PropsDescripcionDependientes> = [
    {
      title: "Cajero",
      dataIndex: "Nombres_Rol",
      key: "Nombres_Rol",
      fixed: "left",
      //...getColumnSearchPropsNewBodegasReporte("usuario"),
    },
    {
      title: "Facturas",
      dataIndex: "facturas",
      key: "facturas",
      //sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Porcentaje",
      dataIndex: "participacion",
      key: "participacion",
      //render: (text, record) => `${text} %`, // Concatena el signo de porcentaje al valor
      render: (_, record) => (
        <span style={{ color: record.participacion === 0 ? "lightgray" : "black" }}>
          {formateaCantidad(record.participacion) + "%"}
        </span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
      //sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Total",
      dataIndex: "totalVenta",
      key: "totalVenta",
      //sorter: (a, b) => a.total - b.total,
      render: (_, record) => (
        <span style={{ color: record.totalVenta === 0 ? "lightgray" : "black" }}>{formateaCantidad(record.totalVenta)}</span>
        // <span style={{ color: record.total === 0 ? "lightgray" : "black" }}>{record.total}</span>
      ),
    },
  ];

  return {
    columns,
    columsDataDependiente,
    columsDataProductos,
    filteredData,
    mes,
    setMes,
    isRowExpandable,
    getRowClassName,
    isModalOpen,
    datakey,
    calcularTotales,
    handleOk,
    handleCancel,
  };
}
