import { FormularioEstado } from "pages/contentrecursoshumanos/contentactualizacion/FormularioEstado";
import { FormulariosPorPasos } from "pages/contentrecursoshumanos/contentactualizacion/FormulariosPorPasos";
import { RevisarInformacion } from "pages/contentrecursoshumanos/contentactualizacion/RevisarInformacion";
import AsignacionSupervisores from "pages/contentrecursoshumanos/contentAsignacionSupervisores/contentAsignacion";
import { QRBodegas } from "pages/contentrecursoshumanos/contentQRBodega/qrBodega";
import { RouteObject } from "react-router-dom";

export const rutasActualizarInformacion: RouteObject[] = [
  {
    path: "/rrhh/formulario",
    element: <FormulariosPorPasos />,
  },
  {
    path: "/rrhh/estado",
    element: <FormularioEstado />,
  },
  {
    path: "/rrhh/revisar-informacion",
    element: <RevisarInformacion />,
  },
  {
    path: "/rrhh/asignacion-supervisores",
    element: <AsignacionSupervisores />,
  },
  {
    path: "/rrhh/qr-bodega",
    element: <QRBodegas />,
  },
];
