import { Alert, Button, Col, DatePicker, Input, List, Modal, Row, Select, Table, Tag, Typography } from "antd"
import { useLayout } from "hooks/otros/useLayout"
import { useTareasFinalizadas } from "hooks/sistemas/useTareasFinalizadas"
import { RollbackOutlined, FileExcelOutlined, EditOutlined } from "@ant-design/icons"
import TextArea from "antd/lib/input/TextArea"
import moment from "moment"

const { Title } = Typography
const { RangePicker } = DatePicker

const breadcrumbs = [
  { Label: "Sistemas", Url: "/sistemas" },
  { Label: "Tareas de Soporte", Url: "/sistemas/ver" },
  { Label: "Finalizadas" },
]
const openKeys = [breadcrumbs[0].Label, `${breadcrumbs[0].Label}/${breadcrumbs[1].Label}`]
const selectKeySider = `${breadcrumbs[1].Label}/${breadcrumbs[2].Label}`

export function TareasFinalizadas() {
  useLayout(breadcrumbs, openKeys, selectKeySider)
  const {
    disabled,
    columnsTareas,
    dataTareas,
    Codtarea,
    Fecha,
    Estado,
    Fecha_Inicio,
    Farmacia,
    usuario_asignado,
    usuario_solicita,
    descripcion_general,
    dataTareasHis,
    setdisabled,
    getTareas,
    incidente,
    Fecha_fin,
    usuario_registra,
    btn_Excel,
    fechasSeleccionadas,
    handleDateChange,
    disabledDate,
    ismodalOpen,
    btn_Editar,
    btn_Cancel,
    onchangeTipoAtencion,
    Tipo_Atencion,
    descripcion,
    Fechahis,
    onchangeDescripcion,
    btn_Guardar,
    showMessage,
    setFechasSeleccionadas
  } = useTareasFinalizadas()
  return (
    <>
      {!disabled && (
        <>
          <Row style={{ background: "white", padding: "5px" }} align="middle" justify="center">
            <Col xs={24} sm={24} md={12} style={{ paddingLeft: "20px" }}>
              <Title level={4}>Tareas Finalizadas</Title>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <Row justify="end">
                <RangePicker
                  size="small"
                  defaultValue={fechasSeleccionadas}
                  format={"YYYY/MM/DD"}
                  disabledDate={disabledDate}
                  onChange={handleDateChange}
                  style={{ marginLeft: "2px", width: "250px", marginRight: "5px" }}
                />
                <Button
                  type="primary"
                  style={{ marginRight: "2px", marginTop: "1px", backgroundColor: "#00B050", borderColor: "#00B050" }}
                  onClick={btn_Excel}
                >
                  Excel <FileExcelOutlined />
                </Button>
              </Row>
            </Col>
          </Row>
          <Row style={{ background: "white", width: "100%", padding: "5px" }} className="table-container">
            <Table style={{ width: "100%" }} columns={columnsTareas} dataSource={dataTareas} />
          </Row>
        </>
      )}
      {disabled && (
        <>
          <Row style={{ background: "white", padding: "5px" }} align="middle" justify="center">
            <Col xs={18} sm={18} md={12} style={{ paddingLeft: "5px" }}>
              <Title level={4}>Tarea Número: {Codtarea}</Title>
            </Col>
            <Col xs={6} sm={6} md={12}>
              <Row justify="end">
                <Button
                  type="default"
                  size="small"
                  onClick={() => {
                    setFechasSeleccionadas([moment(fechasSeleccionadas[0]), moment(fechasSeleccionadas[1])])
                    setdisabled(false)
                    getTareas()
                  }}
                  style={{ marginRight: "2px", marginBottom: "2px" }}
                >
                  <RollbackOutlined />
                  Regresar
                </Button>
              </Row>
            </Col>
          </Row>
          <Row style={{ background: "white", paddingInline: "5px" }} align={"middle"}>
            <Col xs={12} md={6} sm={12} style={{ paddingLeft: "4px", marginTop: "8px" }}>
              <Row>Fecha de Registro</Row>
              <DatePicker style={{ width: "100%" }} disabled size="small" value={Fecha} showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm" />
            </Col>
            <Col xs={12} md={6} sm={12} style={{ paddingLeft: "4px", marginTop: "8px" }}>
              <Row>Estado</Row>
              <Select
                disabled
                style={{ width: "100%" }}
                size="small"
                value={Estado}
                options={[
                  { value: "", label: "PENDIENTE", disabled: true },
                  { value: "EN PROCESO", label: "EN PROCESO", disabled: true },
                  { value: "FINALIZADO", label: "FINALIZADO" },
                ]}
              />
            </Col>
            <Col xs={12} md={6} sm={12} style={{ paddingLeft: "4px", marginTop: "8px" }}>
              <Row>Fecha de Inicio</Row>
              <DatePicker style={{ width: "100%" }} size="small" value={Fecha_Inicio} showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm" disabled />
            </Col>
            <Col xs={12} md={6} sm={12} style={{ paddingLeft: "4px", marginTop: "8px" }}>
              <Row>Fecha Fin</Row>
              <DatePicker style={{ width: "100%" }} size="small" value={Fecha_fin} showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm" disabled />
            </Col>
          </Row>
          <Row style={{ background: "white", paddingInline: "5px" }}>
            <Col xs={24} md={12} sm={24} style={{ paddingLeft: "4px", marginTop: "8px" }}>
              <Row>Farmacia</Row>
              <Input
                style={{ width: "100%", background: "white", color: "#303030" }}
                size="small"
                value={Farmacia}
                disabled
              />
            </Col>
            <Col sm={24} md={12} xs={24} style={{ paddingLeft: "2px", marginTop: "8px" }}>
              <Row>Solicitado por</Row>
              <Input size="small" style={{ background: "white", color: "#303030" }} value={usuario_solicita} disabled />
            </Col>
          </Row>
          <Row style={{ background: "white", paddingInline: "5px" }}>
            <Col sm={24} md={12} xs={24} style={{ paddingLeft: "4px", marginTop: "8px" }}>
              <Row>Asignado a</Row>
              <Input size="small" style={{ background: "white", color: "#303030" }} value={usuario_asignado} disabled />
            </Col>
            <Col sm={24} md={12} xs={24} style={{ paddingLeft: "4px", marginTop: "8px" }}>
              <Row>Registrado por</Row>
              <Input size="small" style={{ background: "white", color: "#303030" }} value={usuario_registra} disabled />
            </Col>
          </Row>
          <Row style={{ background: "white", paddingInline: "5px" }}>
            <Row style={{ paddingLeft: "4px", marginTop: "8px" }}>Tipo de Incidente</Row>
            <Input value={incidente} style={{ background: "white", color: "#303030" }} disabled />
          </Row>
          <Row style={{ background: "white", paddingInline: "5px" }}>
            <Col sm={24} xs={24} md={24} style={{ marginTop: "8px" }}>
              <Row>Descripción General</Row>
              <TextArea rows={2} style={{ background: "white", color: "#303030" }} value={descripcion_general} disabled />
            </Col>
          </Row>
          <Row style={{ background: "white", paddingInline: "5px", paddingTop: "10px" }}>
            <Title style={{ fontSize: "20px", marginLeft: "5px" }}>Historial</Title>
          </Row>
          {dataTareasHis.length > 0 && (
            <>
              <Row style={{ background: "white", paddingInline: "8px", paddingTop: "10px" }}>
                <List
                  style={{ width: "100%" }}
                  itemLayout="vertical"
                  size="small"
                  dataSource={dataTareasHis}
                  renderItem={(item) => (
                    <Row
                      style={{
                        border: "1px solid #D9D9D9",
                        width: "100%",
                        borderRadius: "5px",
                        padding: "5px",
                        marginBottom: "8px",
                      }}
                      align={"middle"}
                    >
                      <Col sm={11} md={12} xs={11}>
                        <Row align={"middle"}>
                          <Title style={{ fontSize: "12px", paddingTop: "5px", paddingRight: "4px" }}>
                            Tipo de Atención:{" "}
                          </Title>
                          <Tag color="blue" style={{ fontSize: "8px" }}>
                            {item.Tipo_Atencion}
                          </Tag>
                        </Row>
                      </Col>
                      <Col sm={13} md={12} xs={13}>
                        <Row align={"middle"} justify={"end"}>
                          <Col sm={17} md={18} xs={17}>
                            <Row>
                              <Title style={{ fontSize: "12px", paddingTop: "0px", paddingRight: "4px" }}>Fecha: </Title>
                              <label style={{ fontSize: "12px" }}>{item.Fecha_Registra.toString()}</label>
                            </Row>
                          </Col>
                          <Col>
                            <Button type="primary" onClick={() => btn_Editar(item)}>
                              <EditOutlined />
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Row style={{ marginTop: "5px" }}>
                        <Row>
                          <Title style={{ fontSize: "12px" }}>Descripción</Title>
                        </Row>
                      </Row>
                      <TextArea
                        style={{ background: "white", color: "#303030" }}
                        value={item.Descripcion}
                        disabled
                      ></TextArea>
                    </Row>
                  )}
                />
              </Row>
            </>
          )}
        </>
      )}
      <Modal
        title="Modificar Historial"
        open={ismodalOpen}
        onCancel={btn_Cancel}
        maskClosable={false}
        footer={[
          <Button key="cancel" onClick={btn_Cancel}>
            Cancelar
          </Button>,
          <Button type="primary" onClick={btn_Guardar}>Guardar</Button>,
        ]}
      >
        <Row>
        <Title style={{ fontSize: "12px", paddingTop: "0px", paddingRight: "4px" }}>Fecha:&nbsp;&nbsp; {Fechahis} </Title>
        </Row>
        <Row>
        <Title style={{ fontSize: "12px", paddingTop: "0px", paddingRight: "4px" }}>Tipo de Atención: </Title>
          <Select
            value={Tipo_Atencion}
            onChange={onchangeTipoAtencion}
            style={{ width: "100%" }}
            options={[
              { label: "Seleccione un Tipo de Atención:", value: "" },
              { label: "PRESENCIAL", value: "PRESENCIAL" },
              { label: "VIRTUAL", value: "VIRTUAL" },
            ]}
          />
        </Row>
        <Row style={{ marginTop: "10px", marginBottom:'10px' }}>
        <Title style={{ fontSize: "12px", paddingTop: "0px", paddingRight: "4px" }}>Descripción: </Title>
          <TextArea value={descripcion} onChange={onchangeDescripcion}></TextArea>
        </Row>
       {showMessage && <Alert  message="Faltan Datos para Guardar" type="warning" showIcon closable />}
      </Modal>
    </>
  )
}
